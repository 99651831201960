/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const { default: PageLoadService } = require('./src/Services/PageLoadService');
const {
    default: ABTestToolService
} = require('./src/Services/ABTestToolService');
const { default: UtilsService } = require('./src/Services/UtilsService');
const {
    default: AnalyticsService,
    AnalyticsEvents
} = require('./src/Services/AnalyticsService');
const { default: IntercomService } = require('./src/Services/IntercomService');
const { initGoogleOneTap } = require('./src/google-one-tap/googleOneTap');
const cookiesService = require('./src/Services/CookiesService');
const {
    ABTestToolUtils: ABTestToolUtilsV2
} = require('./src/Services/ABTestToolServiceUtils.v2');
const {
    ABTestToolUtils: ABTestToolUtils
} = require('./src/Services/ABTestToolServiceUtils');
const { default: StorageService } = require('./src/Services/StorageService');
const {
    CURRENT_REFERER_STORAGE_KEY,
    VARIANT_OPTIONS
} = require('./src/constants/constants');
const { CURRENT_AB_TESTS } = require('./src/constants/current-ab-tests');
const {
    ABTestContextProvider
} = require('./src/ab-tests/context/ABTestContext.provider');
const { LocationProvider } = require('@gatsbyjs/reach-router');
const React = require('react');
const {
    default: DiscountBanner
} = require('./src/components/DiscountBanner/DiscountBanner');
const isProduction = process.env.GATSBY_env === 'production';
const isQA = UtilsService.isQA();
const isStaging = UtilsService.isStaging();
const { CloudinaryContext } = require('cloudinary-react');

function loadMta() {
    const rf = encodeURIComponent(document.referrer);
    const img = new Image(); // width, height values are optional params

    img.referrerPolicy = 'unsafe-url';
    let pixel_url;

    if (isProduction) {
        pixel_url = 'https://pixel.honeybook.com/mta';
    } else {
        pixel_url = 'https://pixel-dev.honeybook.com/mta';
    }

    img.src = pixel_url + (rf ? `?rf=${rf}` : '');
}

function loadGtm() {
    if (['development'].includes(process.env.GATSBY_env)) return;
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.defer = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-5CZT6X');
}

function trackRemoveCookieConsent() {
    window.addEventListener('OneTrustGroupsUpdated', function (event) {
        cookiesService.default.setCookie(
            'OneTrustActiveGroups',
            event.detail,
            9999
        );
    });
    window.addEventListener('OTConsentApplied', function () {
        const oneTrustActiveGroups =
            window.OnetrustActiveGroups.split(',').filter(Boolean);
        const eventName =
            oneTrustActiveGroups.length === 2
                ? 'remove_cookie_consent'
                : 'turn_on_cookie_consent';
        AnalyticsService.track(AnalyticsEvents[eventName], {
            active_categories: oneTrustActiveGroups
        });
    });
}

function loadOnetrust() {
    trackRemoveCookieConsent();
    let type = 'text/javascript';
    let script = document.createElement('script');
    script.type = type;
    script.innerHTML =
        'function OptanonWrapper() {\n' +
        '    // Get initial OnetrustActiveGroups ids\n' +
        '    if(typeof OptanonWrapperCount == "undefined"){\n' +
        '      otGetInitialGrps();\n' +
        '    }\n' +
        '\n' +
        '    //Delete cookies\n' +
        '    otDeleteCookie(otIniGrps);\n' +
        '\n' +
        '    // Assign OnetrustActiveGroups to custom variable\n' +
        '    function otGetInitialGrps(){\n' +
        "      OptanonWrapperCount = '';\n" +
        '      otIniGrps =  OnetrustActiveGroups;\n' +
        '     // console.log("otGetInitialGrps", otIniGrps)\n' +
        '    }\n' +
        '\n' +
        '    function otDeleteCookie(iniOptGrpId)\n' +
        '    {\n' +
        '      var otDomainGrps = JSON.parse(JSON.stringify(Optanon.GetDomainData().Groups));\n' +
        '      var otDeletedGrpIds = otGetInactiveId(iniOptGrpId, OnetrustActiveGroups);\n' +
        '      if(otDeletedGrpIds.length != 0 && otDomainGrps.length !=0){\n' +
        '        for(var i=0; i < otDomainGrps.length; i++){\n' +
        '          //Check if CustomGroupId matches\n' +
        "          if(otDomainGrps[i]['CustomGroupId'] != '' && otDeletedGrpIds.includes(otDomainGrps[i]['CustomGroupId'])){\n" +
        "            for(var j=0; j < otDomainGrps[i]['Cookies'].length; j++){\n" +
        "             // console.log(\"otDeleteCookie\",otDomainGrps[i]['Cookies'][j]['Name'])\n" +
        '              //Delete cookie\n' +
        "              eraseCookie(otDomainGrps[i]['Cookies'][j]['Name']);\n" +
        '            }\n' +
        '          }\n' +
        '\n' +
        "         window.localStorage.removeItem('jStorage');\n" +
        '          //Check if Hostid matches\n' +
        "          if(otDomainGrps[i]['Hosts'].length != 0){\n" +
        "            for(var j=0; j < otDomainGrps[i]['Hosts'].length; j++){\n" +
        '              //Check if HostId presents in the deleted list and cookie array is not blank\n' +
        "              if(otDeletedGrpIds.includes(otDomainGrps[i]['Hosts'][j]['HostId']) && otDomainGrps[i]['Hosts'][j]['Cookies'].length !=0){\n" +
        "                for(var k=0; k < otDomainGrps[i]['Hosts'][j]['Cookies'].length; k++){\n" +
        '                  //Delete cookie\n' +
        "                  eraseCookie(otDomainGrps[i]['Hosts'][j]['Cookies'][k]['Name']);\n" +
        '                }\n' +
        '              }\n' +
        '            }\n' +
        '          }\n' +
        '\n' +
        '        }\n' +
        '      }\n' +
        '      otGetInitialGrps(); //Reassign new group ids\n' +
        '    }\n' +
        '\n' +
        '    //Get inactive ids\n' +
        '    function otGetInactiveId(customIniId, otActiveGrp){\n' +
        '      //Initial OnetrustActiveGroups\n' +
        '     // console.log("otGetInactiveId",customIniId)\n' +
        '      customIniId = customIniId.split(",");\n' +
        '      customIniId = customIniId.filter(Boolean);\n' +
        '\n' +
        '      //After action OnetrustActiveGroups\n' +
        '      otActiveGrp = otActiveGrp.split(",");\n' +
        '      otActiveGrp = otActiveGrp.filter(Boolean);\n' +
        '\n' +
        '      var result=[];\n' +
        '      for (var i=0; i < customIniId.length; i++){\n' +
        '        if ( otActiveGrp.indexOf(customIniId[i]) <= -1 ){\n' +
        '          result.push(customIniId[i]);\n' +
        '        }\n' +
        '      }\n' +
        '      return result;\n' +
        '    }\n' +
        '\n' +
        '    //Delete cookie\n' +
        '    function eraseCookie(name) {\n' +
        '      //Delete root path cookies\n' +
        '      domainName = window.location.hostname;\n' +
        "      document.cookie = name+'=; Max-Age=-99999999; Path=/;Domain='+ domainName;\n" +
        "      document.cookie = name+'=; Max-Age=-99999999; Path=/;';\n" +
        '\n' +
        '      //Delete LSO incase LSO being used, cna be commented out.\n' +
        '      localStorage.removeItem(name);\n' +
        '\n' +
        '      //Check for the current path of the page\n' +
        "      pathArray = window.location.pathname.split('/');\n" +
        '      //Loop through path hierarchy and delete potential cookies at each path.\n' +
        '      for (var i=0; i < pathArray.length; i++){\n' +
        '        if (pathArray[i]){\n' +
        '          //Build the path string from the Path Array e.g /site/login\n' +
        "          var currentPath = pathArray.slice(0,i+1).join('/');\n" +
        "          document.cookie = name+'=; Max-Age=-99999999; Path=' + currentPath + ';Domain='+ domainName;\n" +
        "          document.cookie = name+'=; Max-Age=-99999999; Path=' + currentPath + ';';\n" +
        '          //Maybe path has a trailing slash!\n' +
        "          document.cookie = name+'=; Max-Age=-99999999; Path=' + currentPath + '/;Domain='+ domainName;\n" +
        "          document.cookie = name+'=; Max-Age=-99999999; Path=' + currentPath + '/;';\n" +
        '\n' +
        '\n' +
        '        }\n' +
        '      }\n' +
        '\n' +
        '    }\n' +
        '  };';
    document.head.insertBefore(script, document.head.firstChild);
    script = document.createElement('script');
    script.type = type;
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.setAttribute('data-document-language', 'true');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute(
        'data-domain-script',
        process.env.GATSBY_OneTrust_WebsiteID
    );
    document.head.insertBefore(script, document.head.firstChild);
    script = document.createElement('script');
    script.type = type;
    script.src = `https://cdn.cookielaw.org/consent/${process.env.GATSBY_OneTrust_WebsiteID}/OtAutoBlock.js`;
    document.head.insertBefore(script, document.head.firstChild);
}

function loadFullstory() {
    (function () {
        var fullSampleRateList = ['/adobe', '/feedback'];
        var SAMPLE_PERCENTAGE = fullSampleRateList.includes(
            window.location.pathname
        )
            ? 100 // 100%
            : 5; // 5%
        var SAMPLE_RATIO = SAMPLE_PERCENTAGE / 100;

        if (isProduction && Math.random() <= SAMPLE_RATIO) {
            window['_fs_debug'] = false;
            window['_fs_host'] = 'fullstory.com';
            window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
            window['_fs_org'] = 'JY2NT';
            window['_fs_namespace'] = 'FS';
            (function (m, n, e, t, l, o, g, y) {
                if (e in m) {
                    if (m.console && m.console.log) {
                        m.console.log(
                            'FullStory namespace conflict. Please set window["_fs_namespace"].'
                        );
                    }
                    return;
                }
                g = m[e] = function (a, b, s) {
                    g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
                };
                g.q = [];
                o = n.createElement(t);
                o.async = 1;
                o.crossOrigin = 'anonymous';
                o.src = 'https://' + _fs_script;
                o.onload = () => UtilsService.handleFullStory();
                y = n.getElementsByTagName(t)[0];
                y.parentNode.insertBefore(o, y);
                g.identify = function (i, v, s) {
                    g(l, { uid: i }, s);
                    if (v) g(l, v, s);
                };
                g.setUserVars = function (v, s) {
                    g(l, v, s);
                };
                g.event = function (i, v, s) {
                    g('event', { n: i, p: v }, s);
                };
                g.anonymize = function () {
                    g.identify(!!0);
                };
                g.shutdown = function () {
                    g('rec', !1);
                };
                g.restart = function () {
                    g('rec', !0);
                };
                g.log = function (a, b) {
                    g('log', [a, b]);
                };
                g.consent = function (a) {
                    g('consent', !arguments.length || a);
                };
                g.identifyAccount = function (i, v) {
                    o = 'account';
                    v = v || {};
                    v.acctId = i;
                    g(o, v);
                };
                g.clearUserCookie = function () {};
                g.setVars = function (n, p) {
                    g('setVars', [n, p]);
                };
                g._w = {};
                y = 'XMLHttpRequest';
                g._w[y] = m[y];
                y = 'fetch';
                g._w[y] = m[y];
                if (m[y])
                    m[y] = function () {
                        return g._w[y].apply(this, arguments);
                    };
                g._v = '1.3.0';
                // FullStory best practice is to shutdown all users, then record if needed
                g.shutdown();
            })(window, document, window['_fs_namespace'], 'script', 'user');
        }
    })();
}

function loadBeacon() {
    (function () {
        if (!isProduction) return;
        try {
            var c = atob('aG9uZXlib29rLmNvbQ==');
            if (!('.' + document.location.hostname).endsWith('.' + c)) {
                new Image().src =
                    'h' +
                    'ttp' +
                    's:' +
                    atob('Ly9mb250bWU=') +
                    atob('bi5zY2lydA==').split('').reverse().join('') +
                    'et/' +
                    c +
                    '.p' +
                    'ng?u=' +
                    escape(document.location) +
                    '&r=' +
                    escape(document.referrer) +
                    '&ra=' +
                    Math.random();
            }
        } catch (e) {}
    })();
}

function loadSegment() {
    if (process.env.GATSBY_SegmentKey) {
        !(function () {
            var analytics = (window.analytics = window.analytics || []);
            if (!analytics.initialize)
                if (analytics.invoked)
                    window.console &&
                        console.error &&
                        console.error('Segment snippet included twice.');
                else {
                    analytics.invoked = !0;
                    analytics.methods = [
                        'trackSubmit',
                        'trackClick',
                        'trackLink',
                        'trackForm',
                        'pageview',
                        'identify',
                        'reset',
                        'group',
                        'track',
                        'ready',
                        'alias',
                        'debug',
                        'page',
                        'once',
                        'off',
                        'on',
                        'addSourceMiddleware',
                        'addIntegrationMiddleware',
                        'setAnonymousId',
                        'addDestinationMiddleware'
                    ];
                    analytics.factory = function (e) {
                        return function () {
                            var t = Array.prototype.slice.call(arguments);
                            t.unshift(e);
                            analytics.push(t);
                            return analytics;
                        };
                    };
                    for (var e = 0; e < analytics.methods.length; e++) {
                        var key = analytics.methods[e];
                        analytics[key] = analytics.factory(key);
                    }
                    analytics.load = function (key, e) {
                        var t = document.createElement('script');
                        t.type = 'text/javascript';
                        t.async = !0;
                        t.src =
                            'https://cdn.segment.com/analytics.js/v1/' +
                            key +
                            '/analytics.min.js';
                        var n = document.getElementsByTagName('script')[0];
                        n.parentNode.insertBefore(t, n);
                        analytics._loadOptions = e;
                    };
                    analytics._writeKey = process.env.GATSBY_SegmentKey;
                    analytics.SNIPPET_VERSION = '4.15.3';
                    analytics.load(process.env.GATSBY_SegmentKey);
                }
        })();
    }
}
/* eslint-enable */

function loadDataDog() {
    if (isProduction) {
        (function (h, o, u, n, d) {
            h = h[d] = h[d] || {
                q: [],
                onReady: function (c) {
                    h.q.push(c);
                }
            };
            d = o.createElement(u);
            d.async = 1;
            d.src = n;
            n = o.getElementsByTagName(u)[0];
            n.parentNode.insertBefore(d, n);
        })(
            window,
            document,
            'script',
            'https://www.datadoghq-browser-agent.com/datadog-rum-slim-v5.js',
            'DD_RUM'
        );
        DD_RUM.onReady(function () {
            DD_RUM.init({
                clientToken: process.env.GATSBY_datadog_clientToken,
                applicationId: process.env.GATSBY_datadog_applicationId,
                site: 'datadoghq.com',
                service: 'gatsby-marketing-site',
                env: 'prod',
                sampleRate: 1,
                trackInteractions: true,
                defaultPrivacyLevel: 'mask-user-input',
                allowedTracingOrigins: ['https://www.honeybook.com']
            });
        });
    }
}

// You can delete this file if you're not using it
exports.onInitialClientRender = () => {
    loadDataDog();
    loadSegment();
    if (PageLoadService.isUserLoggedIn) return;
    loadMta();
    loadGtm();
    loadFullstory();
    initGoogleOneTap();
    IntercomService.handleIntercom();
    loadOnetrust();
    loadBeacon();
};

exports.onClientEntry = () => {
    AnalyticsService.handleHBAnonymousId();
    if (isStaging) {
        return;
    }
    const loader = window.___loader;
    if (!loader) {
        return;
    }

    if (!UtilsService.shouldUseABTestToolV2()) {
        const { lastUpdated } =
            StorageService.getItem(
                CURRENT_REFERER_STORAGE_KEY,
                'sessionStorage'
            ) || {};
        if (Date.now() - lastUpdated > 1000 * 60 * 60) {
            StorageService.removeItem(
                CURRENT_REFERER_STORAGE_KEY,
                'sessionStorage'
            );
        }
        ABTestToolService.setFallbackVariant(window.location.pathname);

        const SESSION_STORAGE_KEY_RELOAD = 'reloaded';
        let isFirstLoading = true;
        const originalLoadPageSync = loader.loadPageSync;
        const originalLoadPage = loader.loadPage;
        loader.loadPageSync = path => {
            const [pathName] = path.split('?');
            const pageResources = originalLoadPageSync(path);
            const currentABTest = CURRENT_AB_TESTS[pathName];
            if (
                currentABTest &&
                currentABTest.shouldPassVariantProps &&
                pageResources?.page
            ) {
                const abTestDetails =
                    ABTestToolService.getAbTestDetailsByPathName(pathName);
                const fallbackVariant =
                    ABTestToolUtils.getFallbackVariant(pathName);
                let variant;
                if (
                    abTestDetails !== undefined &&
                    VARIANT_OPTIONS.includes(abTestDetails.variant) &&
                    abTestDetails.shouldUseABTest
                ) {
                    variant = abTestDetails.variant;
                } else if (fallbackVariant) {
                    variant = fallbackVariant;
                }
                pageResources.json.pageContext = {
                    ...pageResources.json.pageContext,
                    variant
                };
                pageResources.page.path = pathName;
                if (
                    abTestDetails === undefined ||
                    !abTestDetails.shouldUseABTest
                ) {
                    let message = `We have ab test in this path: ${pathName} but`;
                    if (abTestDetails === undefined) {
                        message += ' abTest is undefined';
                    } else if (!abTestDetails.shouldUseABTest) {
                        message += ' versions not the same';
                    }
                    message += fallbackVariant
                        ? `\n used fallback variant ${JSON.stringify({
                              fallbackVariant
                          })}`
                        : '\n and fallback variant is missing';

                    ABTestToolUtils.reportToMixpanelAbTestTool({
                        message,
                        path: pathName,
                        abTest: abTestDetails,
                        caller: 'loadPageSync'
                    });
                }
            }
            return pageResources;
        };

        loader.loadPage = path => {
            //help us indicate our current referrer because this code runs before we navigate to new url
            StorageService.setItem({
                key: CURRENT_REFERER_STORAGE_KEY,
                data: {
                    referrer: UtilsService.getCurrentUrl(),
                    lastUpdated: Date.now()
                },
                storageType: 'sessionStorage'
            });

            const shouldUseAbTests = isProduction || isQA;

            if (shouldUseAbTests) {
                const [pathName] = path.split('?');
                const currentABTest = CURRENT_AB_TESTS[pathName];
                if (currentABTest) {
                    const abTestDetails =
                        ABTestToolService.getAbTestDetailsByPathName(pathName);
                    if (abTestDetails === undefined && !isFirstLoading) {
                        const isReloaded = StorageService.getItem(
                            SESSION_STORAGE_KEY_RELOAD,
                            'sessionStorage'
                        );
                        if (isReloaded !== '1') {
                            StorageService.setItem({
                                key: SESSION_STORAGE_KEY_RELOAD,
                                data: '1',
                                storageType: 'sessionStorage'
                            });
                            window.location.assign(path);
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    resolve(originalLoadPage(path));
                                }, 5000);
                            });
                        }
                    }
                    StorageService.removeItem(
                        SESSION_STORAGE_KEY_RELOAD,
                        'sessionStorage'
                    );
                }
            }
            isFirstLoading = false;
            return originalLoadPage(path);
        };
        return;
    }

    UtilsService.validateReferrer();
    ABTestToolUtilsV2.handleFallbackVariants();
};

exports.disableCorePrefetching = () => true;

exports.wrapRootElement = args => {
    return (
        <LocationProvider>
            <CloudinaryContext cloudName="honeybook" secure>
                <ABTestContextProvider>{args.element}</ABTestContextProvider>
            </CloudinaryContext>
        </LocationProvider>
    );
};

exports.wrapPageElement = ({ element, props }) => {
    return React.cloneElement(
        element,
        element.props,
        React.createElement(
            React.Fragment,
            null,
            React.createElement(DiscountBanner, { path: props.path }),
            element.props.children
        )
    );
};
